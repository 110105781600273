<template>
    <v-btn
        block
        elevation="1"
        plain
        x-large
        @click="redirect(schema.target)"
        class="my-2 py-8 justify-start"
    >
        <v-icon large color="primary" class="mr-4">mdi-{{ icon }}</v-icon>
        <div
            class="custom-transform-class text-none text-subtitle-1"
            v-text="label"
        />
    </v-btn>
</template>

<script>
export default {
    props: {
        schema: { type: Object, required: true },
        label: { type: String, default: '' },
        htmlDescription: { type: String, default: '' },
        responseId: { type: String, required: true },
    },
    computed: {
        user() {
            const { name, email, employeeId } = this.$store.getters[
                'auth/getUser'
            ]
            const firstName = name.split(' ')[0]
            const lastName = name.split(' ').pop()
            return {
                name,
                'name[first]': firstName,
                'name[last]': lastName,
                email,
                employeeId,
            }
        },
        icon() {
            const config = this.schema['x-config']
            return config ? config.icon : null
        },
    },
    methods: {
        redirect({ url, passImplicitParams, staticParams }) {
            let targetUrl = new URL(url)
            let params = {}
            if (passImplicitParams) {
                // If passImplicitParams is true, pass implicit user parameters and the response ID.
                params = {
                    ...params,
                    ...this.user,
                    responseId: this.responseId,
                }
            }
            if (staticParams) {
                // If passImplicitParams is available, add include them with the parameters.
                params = {
                    ...params,
                    ...staticParams,
                }
            }
            // Add each of the params to the URL as search params.
            Object.keys(params).map((key) =>
                targetUrl.searchParams.append(key, params[key])
            )
            window.open(targetUrl, '_blank')
        },
    },
}
</script>

<style scoped></style>
